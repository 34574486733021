import React from "react";

import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import UserIcon from "@material-ui/icons/SupervisorAccount";
import Centers from "@material-ui/icons/Room";
import Wallets from "@material-ui/icons/AccountBalanceWallet";
import PaymentIcon from "@material-ui/icons/Payment";
import LayersIcon from "@material-ui/icons/Layers";
import OrderIcon from "@material-ui/icons/Receipt";
import ProductIcon from "@material-ui/icons/List";
import MenuBookIcon from "@material-ui/icons/Notes";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import SettingsIcon from "@material-ui/icons/Settings";
import {useSelector} from "react-redux";

export const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflow: "auto",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

function SideMenu(props) {
  const user = useSelector((state) => state.user.user);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
      open={props.open}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/");
          }}
          key={"home"}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Products");
          }}
          key={"products"}
        >
          <ListItemIcon>
            <ProductIcon />
          </ListItemIcon>
          <ListItemText primary={"Products"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Users");
          }}
          key={"users"}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary={"Users"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Orders");
          }}
          key={"orders"}
        >
          <ListItemIcon>
            <OrderIcon />
          </ListItemIcon>
          <ListItemText primary={"Orders"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Farmers");
          }}
          key={"farmers"}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary={"Farmers"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Centers");
          }}
          key={"centers"}
        >
          <ListItemIcon>
            <Centers />
          </ListItemIcon>
          <ListItemText primary={"Centers"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Wallets");
          }}
          key={"wallets"}
        >
          <ListItemIcon>
            <Wallets />
          </ListItemIcon>
          <ListItemText primary={"Wallets"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Transactions");
          }}
          key={"transactions"}
        >
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary={"Transactions"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Report");
          }}
          key={"report"}
        >
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary={"Report"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Categories");
          }}
          key={"categories"}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary={"Categories"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            props.redirectPage("/Layouts");
          }}
          key={"layouts"}
        >
          <ListItemIcon>
            <ViewCompactIcon />
          </ListItemIcon>
          <ListItemText primary={"Layouts"} />
        </ListItem>

        {user && (user.role === "admin" || user.role === "superadmin") ? (
          <ListItem
            button
            onClick={() => {
              props.redirectPage("/Settings");
            }}
            key={"settings"}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItem>
        ) : null}
      </List>
    </Drawer>
  );
}

export default SideMenu;
