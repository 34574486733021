import config from "./env";
import {authHeader} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const walletService = {
  getAllWallet,
  fetchWallet,
  addMoney,
};

function getAllWallet(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/wallet/allAllWallet?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchWallet(walletId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let path = `${config.apiUrl}/wallet/getWalletForId?id=${walletId}`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addMoney(values) {
  let param = {walletId: values.walletId};
  param.amount = values.amount;
  param.note = values.note;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/wallet/addMoney`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
