import React, {useState} from "react";

import Menu from "./Menu";
import SideMenu from "./SideMenu";

import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    marginTop: "70px",
  },
}));

function MenuContainer(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function redirectPage(target) {
    props.history.push(target);
  }

  return (
    <div>
      <CssBaseline />
      <Menu
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleLogout={props.handleLogout}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <SideMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          redirectPage={redirectPage}
        />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </div>
    </div>
  );
}

export default MenuContainer;
