import React from "react";
import {connect} from "react-redux";
import {Route, Switch, withRouter, Redirect} from "react-router-dom";

import {setupActions, userActions} from "../businessLogic/actions";
import MenuContainer from "../components/MenuContainer";

import LinearProgress from "@material-ui/core/LinearProgress";

const Home = React.lazy(() => import("./Home"));
const Login = React.lazy(() => import("./Login"));
const Register = React.lazy(() => import("./Register"));
const Orders = React.lazy(() => import("../UI/containers/order/Orders"));
const Transactions = React.lazy(() =>
  import("../UI/containers/transaction/Transactions")
);
const Wallets = React.lazy(() => import("../UI/containers/wallet/Wallets"));
const Products = React.lazy(() => import("../UI/containers/product/Products"));
const Centers = React.lazy(() => import("../UI/containers/center/Center"));
const Layouts = React.lazy(() => import("../UI/containers/layouts/Layouts"));
const Settings = React.lazy(() => import("./Settings"));
const Users = React.lazy(() => import("./Users"));
const OTPValidate = React.lazy(() => import("./OTPValidate"));
const Farmers = React.lazy(() => import("./Farmers"));
const Categories = React.lazy(() => import("./Categories"));
const Report = React.lazy(() => import("../UI/containers/report/report"));

class MainRoot extends React.Component {
  componentDidMount() {
    this.props.loadBoot();
  }

  render() {
    if (this.props.user.isBootLoaded == null) {
      return <LinearProgress />;
    }

    if (this.props.user.isBootLoaded === false) {
      return (
        <div>
          We have some problem in loading page. Please try after some time.
        </div>
      );
    }

    if (this.props.user.user === null) {
      return (
        <React.Suspense fallback={<p>Please wait</p>}>
          <Switch>
            <Route path="/Register" component={Register} />
            <Route path="/Login" exact component={Login} />
            <Route path="/Validate" exact component={OTPValidate} />
            <Route path="/" exact component={Login} />
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      );
    }

    return (
      <MenuContainer
        history={this.props.history}
        handleLogout={this.props.handleLogout}
      >
        <React.Suspense fallback={<p>Please wait</p>}>
          <Switch>
            <Route path="/Home" component={Home} />
            <Route path="/Orders" component={Orders} />
            <Route path="/Products" component={Products} />
            <Route path="/Layouts" component={Layouts} />
            <Route path="/Report" component={Report} />
            <Route path="/Users" component={Users} />
            <Route path="/Categories" component={Categories} />
            <Route path="/Farmers" component={Farmers} />
            <Route path="/Centers" component={Centers} />
            <Route path="/Wallets" component={Wallets} />
            <Route path="/Transactions" component={Transactions} />
            <Route path="/Settings" component={Settings} />
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </MenuContainer>
    );
  }
}

const mapDispatchToProps = {
  loadBoot: setupActions.loadBoot,
  handleLogout: userActions.logout,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainRoot)
);
