import {UserConstants, BootSetup} from "../actions/types";

const initialState = {
  isBootLoaded: null,
  isAdminAvailable: null,
  user: null,
  nonVarifiedUser: null,
  isRequestInProgress: false,
  error: null,
  orderFilter: {},
  productFilter: {},
};

function user(state = initialState, action) {
  const userInfo = localStorage.getItem("user");
  const info = userInfo ? JSON.parse(userInfo) : null;

  if (info != null) {
    state = {
      ...state,
      isBootLoaded: true,
      isAdminAvailable: true,
      user: info,
      orderFilter: {},
      productFilter: {},
    };
  }
  if (action.type === BootSetup.BOOT_LOADED) {
    return {
      ...state,
      isBootLoaded: true,
      isAdminAvailable: action.payload.isSetup,
    };
  }
  if (action.type === BootSetup.BOOT_FAILED) {
    return {
      ...state,
      isBootLoaded: false,
      isAdminAvailable: null,
    };
  }
  if (action.type === UserConstants.REGISTER_REQUEST) {
    return {
      ...state,
      isRequestInProgress: true,
    };
  }
  if (action.type === UserConstants.REGISTER_SUCCESS) {
    return {
      ...state,
      isRequestInProgress: false,
      isAdminAvailable: true,
      user: action.user,
      error: null,
    };
  }
  if (action.type === UserConstants.REGISTER_FAILURE) {
    return {
      ...state,
      isRequestInProgress: false,
      user: null,
      error: action.error,
    };
  }

  if (action.type === UserConstants.NEED_OTP_VALIDATION) {
    return {
      ...state,
      isRequestInProgress: false,
      isAdminAvailable: true,
      nonVarifiedUser: action.user,
      error: null,
    };
  }
  if (action.type === UserConstants.CLEAR_ERROR) {
    return {
      ...state,
      error: null,
    };
  }
  if (action.type === UserConstants.LOGIN_REQUEST) {
    return {
      ...state,
      isRequestInProgress: true,
    };
  }
  if (action.type === UserConstants.LOGIN_SUCCESS) {
    return {
      ...state,
      isRequestInProgress: false,
      isAdminAvailable: true,
      user: action.user,
      nonVarifiedUser: null,
      error: null,
    };
  }
  if (action.type === UserConstants.LOGIN_FAILURE) {
    return {
      ...state,
      isRequestInProgress: false,
      user: null,
      error: action.error,
    };
  }

  if (action.type === UserConstants.LOGOUT) {
    return {
      ...state,
      user: null,
      error: null,
    };
  }

  if (action.type === UserConstants.PRODUCT_UPDATE) {
    return {
      ...state,
      productFilter: action.filter,
    };
  }

  return state;
}

export default user;
