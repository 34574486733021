import config from "./env";
import {authHeader, headerForMulipart} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const categoryService = {
  getAllCategories,
  createCategory,
  deleteCategory,
  updateCategory,
};

function getAllCategories() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/category/getAll`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createCategory(param) {
  const formData = new FormData();
  formData.append("name", param.name);
  formData.append("desc", param.desc);
  formData.append("category", param.category);

  const requestOptions = {
    method: "POST",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/category/createCategory`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteCategory(categoryId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/category/${categoryId}`, requestOptions).then(
    handleResponse
  );
}

function updateCategory(param) {
  const formData = new FormData();
  formData.append("name", param.name);
  formData.append("desc", param.desc);

  if (param.category !== null) {
    formData.append("category", param.category);
  }

  const requestOptions = {
    method: "PUT",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/category/${param.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
