import config from "./env";
import {authHeader, headerForMulipart} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const productService = {
  getAllProduct,
  createNewProduct,
  deleteProduct,
  updateProduct,
  updateAvailability,
  updateBelongs,
  fetchProduct,
  updatedMovedProducts,
};

function fetchProduct(productId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/product/${productId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getAllProduct(offset, rowPerPage, filter) {
  let param = filter;
  if (typeof filter.isServer === "undefined") {
    param.isServer = true;
  }
  param.size = rowPerPage;
  param.offset = offset;

  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/product/fetchProduct`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateAvailability(param) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/product/updateAvailability`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updatedMovedProducts(param) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/product/updatedMovedProducts`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateBelongs(param, type) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };
  console.log();

  let path = type === "center" ? "updateCenter" : "updateBazar";
  return fetch(`${config.apiUrl}/product/${path}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createNewProduct(param) {
  const formData = new FormData();
  formData.append("farmerId", param.farmerId);
  formData.append("pName", param.pName);
  formData.append("pDesc", param.pDesc);
  formData.append("pType", param.pType);
  formData.append("pCategory", param.pCategory);
  formData.append("hasVariants", param.hasVariants);
  formData.append("isFloating", param.isFloating);
  formData.append("pricePerUnit", param.pricePerUnit);
  formData.append("unit", param.unit);
  formData.append("productImage", param.productImage);
  formData.append("available", param.available);

  if (param.pVariants && param.pVariants.length > 0) {
    let list = JSON.stringify(param.pVariants);
    formData.append("pVariants", list);
  }

  const requestOptions = {
    method: "POST",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/product/createProduct`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteProduct(productId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/product/${productId}`, requestOptions).then(
    handleResponse
  );
}

function updateProduct(param) {
  const formData = new FormData();
  formData.append("farmerId", param.farmerId);
  formData.append("pName", param.pName);
  formData.append("pDesc", param.pDesc);
  formData.append("pType", param.pType);
  formData.append("pCategory", param.pCategory);
  formData.append("hasVariants", param.hasVariants);
  formData.append("isFloating", param.isFloating);
  formData.append("pricePerUnit", param.pricePerUnit);
  formData.append("unit", param.unit);
  formData.append("productImage", param.productImage);
  formData.append("available", param.available);

  if (param.pVariants && param.pVariants.length > 0) {
    let list = JSON.stringify(param.pVariants);
    formData.append("pVariants", list);
  }

  const requestOptions = {
    method: "PUT",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/product/${param.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
