export const handleResponse = handleServerResponse;

function handleServerResponse(response) {
  return response.text().then((text) => {
    const data = JSON.parse(text);

    if (!response.ok) {
      throw data.error;
    }

    return data.result;
  });
}
