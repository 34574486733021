import {CategoryConstants} from "../actions/types";

const initialState = {
  allCategories: null,
  isRequestInProgress: false,
  error: null,
};

function category(state = initialState, action) {
  if (action.type === CategoryConstants.FETCH_CATEGORIES_REQUEST) {
    return {
      ...state,
      isRequestInProgress: true,
    };
  }
  if (action.type === CategoryConstants.FETCH_CATEGORIES_SUCCESS) {
    let categoriesInfo = action.categories;
    if (state.allcategories != null) {
      categoriesInfo = state.allcategories.concat(action.categories);
    }

    return {
      ...state,
      isRequestInProgress: false,
      allCategories: categoriesInfo,
    };
  }
  if (action.type === CategoryConstants.CATEGORIES_FAIL) {
    return {
      ...state,
      isRequestInProgress: false,
      error: action.error,
    };
  }

  return state;
}

export default category;
