const config = require("./config.json");

console.log(process.env);
const env = process.env.REACT_APP_CONFIG || "production";
console.log(env);
const apiUrl = config[env].apiUrl;

module.exports = {
  apiUrl,
};
