import config from "./env";
import {authHeader, headerForMulipart} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

const addParam = (url, param) => {
  let paramArray = [];
  for (const key in param) {
    if (param[key]) {
      paramArray.push(`${key}=${param[key]}`);
    }
  }
  if (paramArray.length) {
    url = url + "?" + paramArray.join("&");
  }
  return url;
};

export const getLayouts = (param) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/layout/getAllLayout`;
  url = addParam(url, param);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const updateLayout = (data, param) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(data),
  };

  let url = `${config.apiUrl}/layout/updateLayout`;
  url = addParam(url, param);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const createLayout = (data, param) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(data),
  };

  let url = `${config.apiUrl}/layout/createLayout`;
  url = addParam(url, param);

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const deleteLayout = (param) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/layout/deleteLayout`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const addLayoutItem = (param) => {
  const formData = new FormData();
  formData.append("id", param.id);
  formData.append("name", param.name);
  if (param.centerId) {
    formData.append("centerId", param.centerId);
  }
  if (param.bazarId) {
    formData.append("bazarId", param.bazarId);
  }
  formData.append("filter", param.filter);
  formData.append("details", param.details);
  formData.append("layoutImage", param.imageUrl);

  const requestOptions = {
    method: "POST",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/layout/additem`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const updateLayoutItem = (param) => {
  const formData = new FormData();
  formData.append("layoutId", param.layoutId);
  formData.append("itemId", param.itemId);
  formData.append("name", param.name);
  formData.append("filter", param.filter);
  formData.append("details", param.details);
  formData.append("layoutImage", param.imageUrl);

  const requestOptions = {
    method: "POST",
    headers: headerForMulipart(),
    body: formData,
  };

  return fetch(`${config.apiUrl}/layout/updateitem`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const deleteLayoutItem = (param) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/layout/deleteitem`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const getLayout = (id, centerId, bazarId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/layout/${id}`;
  url = addParam(url, {bazarId, centerId});

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};
