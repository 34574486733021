import {applyMiddleware, createStore, compose} from "redux";

import thunk from "redux-thunk";
import {createLogger} from "redux-logger";

import promise from "./promise";
import array from "./array";
import reducers from "../reducers";

const isDebuggingInChrome = true;

const logger = createLogger({
  predicate: (getState, action) => isDebuggingInChrome,
  collapsed: true,
  duration: true,
});

const middlewareEnhancer = applyMiddleware(thunk, promise, array, logger);
const composedEnhancers = compose(middlewareEnhancer);

function configureStore(state = {}) {
  const store = createStore(reducers, undefined, composedEnhancers);

  return {store: store};
}

export default configureStore;
