import config from "./env";
import {authHeader} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const transactionService = {
  getAllTransactions,
  getTransactionForId,
  closeBazar,
};

function getTransactionForId(transactionId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let path = `${config.apiUrl}/transaction/getTransactionForId?id=${transactionId}`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getAllTransactions(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/transaction/getAllTransactions?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchTransaction(walletId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let path = `${config.apiUrl}/wallet/getWalletForId?id=${walletId}`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function closeBazar(bazarId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({bazarId}),
  };

  return fetch(`${config.apiUrl}/bazar/closeBazar`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
