import config from "./env";
import {authHeader} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const bazarService = {
  getAllBazar,
  updateBazar,
  createNewBazar,
  fetchBazar,
  closeBazar,
};

function getAllBazar(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/bazar/getAll?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function closeBazar(bazarId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({bazarId}),
  };

  return fetch(`${config.apiUrl}/bazar/closeBazar`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateBazar(paramInfo) {
  let startDate = new Date(paramInfo.startDate);
  let endDate = new Date(paramInfo.endDate);

  let param = {};
  param.name = paramInfo.name;
  param.startDate = startDate;
  param.endDate = endDate;
  param.centerId = paramInfo.centerId;
  param.bazarId = paramInfo.bazarId;
  param.comment = paramInfo.comment;
  param.expectedDelivery = paramInfo.expectedDelivery;
  param.willSupplyAll = paramInfo.willSupplyAll;
  param.regions = paramInfo.subRegions;
  param.freeShippingLimit = paramInfo.freeShippingLimit;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/bazar/updateBazar`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createNewBazar(paramInfo) {
  let startDate = new Date(paramInfo.startDate);
  let endDate = new Date(paramInfo.endDate);

  let param = {};
  param.name = paramInfo.name;
  param.startDate = startDate;
  param.endDate = endDate;
  param.centerId = paramInfo.centerId;
  param.comment = paramInfo.comment;
  param.expectedDelivery = paramInfo.expectedDelivery;
  param.willSupplyAll = paramInfo.willSupplyAll;
  param.oldBazarId = paramInfo.oldBazarId;
  param.regions = paramInfo.subRegions;
  param.freeShippingLimit = paramInfo.freeShippingLimit;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/bazar/createBazar`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchBazar(bazarId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/bazar/${bazarId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
