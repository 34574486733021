import config from "./env";
import {authHeader, headerForMulipart} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const centerService = {
  fetchAllCenter,
  updateCenter,
  createCenter,
  fetchCenter,
  createSubregion,
  updateSubregion,
  deleteSubregion,
  centerForIds,
  fetchPickUpPoints,
  addPickupPoint,
  updatePickupPoint,
  deletePickupPoint,
};

function fetchAllCenter(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value !== "") {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/center/getAll?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function centerForIds(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/centerForIds`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateCenter(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/updateCenter`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createCenter(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/createCenter`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchCenter(centerId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/center/${centerId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createSubregion(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/addSubRegion`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateSubregion(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/updateSubRegion`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteSubregion(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/removeSubRegion`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchPickUpPoints(pincode) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let path = `${config.apiUrl}/center/pickupPointForPin?pincode=${pincode}`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addPickupPoint(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/addPickupPoint`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updatePickupPoint(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/updatePickupPoint`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deletePickupPoint(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/center/deletePickupPoint`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
