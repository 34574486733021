import config from "./env";
import {authHeader} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const orderService = {
  getAllOrders,
  orderForExport,
  fetchUsersOrderHistory,
  updateOrder,
  createNewOrder,
  fetchOrder,
  cancelOrder,
  initiateOrder,
  confirmOrders,
  dispatchOrder,
  refundOrder,
  delivered,
  deleteOrder,
  paymentStatus,
  pinValidation,
  fetchOrderForEdit,
  consolidatedItemsForBazar,
  sendBill,
  updateStatus,
  fetchOrderForReceipt,
  getReport,
  generateFarmersBill,
  regionForPincode,
};

function getAllOrders(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/order/orders?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function orderForExport(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/order/ordersForExport?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchUsersOrderHistory(offset, rowPerPage, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let param = "";
  for (const [key, value] of Object.entries(filter)) {
    if (value.length > 0) {
      param = param + `&${key}=${value}`;
    }
  }
  let path = `${config.apiUrl}/order/getPhoneNumber?size=${rowPerPage}&offset=${offset}`;
  if (param.length > 0) {
    path = path + param;
  }

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function pinValidation(pinCode, regionId, bazarId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let param = "?";
  if (pinCode) {
    param = param + `pinCode=${pinCode}`;
  }

  if (regionId) {
    if (param.length !== 1) {
      param = param + `&`;
    }
    param = param + `regionId=${regionId}`;
  }

  if (bazarId) {
    if (param.length !== 1) {
      param = param + `&`;
    }
    param = param + `bazarId=${bazarId}`;
  }

  let path = `${config.apiUrl}/order/pinValidation${param}`;
  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function sendBill(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let path = `${config.apiUrl}/order/sendBill?orderId=${orderId}`;
  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchOrder(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/order/${orderId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function consolidatedItemsForBazar(bazarId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/order/consolidatedItems?bazarId=${bazarId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchOrderForEdit(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/order/orderWithProduct/${orderId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateOrder(paramInfo) {
  let param = {};
  param.orderId = paramInfo.orderId;
  param.name = paramInfo.name;
  param.contactNumber = paramInfo.contactNumber;
  param.shouldUpdate = paramInfo.shouldUpdate;
  param.address = paramInfo.address;
  param.packingComment = paramInfo.packingComment;

  if (paramInfo.region) {
    param.regionId = paramInfo.region._id;
  }

  let items = [];
  for (const item of paramInfo.items) {
    let info = {};
    info.productId = item.product.id;
    info.quantity = item.quantity;
    if (item.variant) {
      info.variantId = item.variant._id;
    }
    if (paramInfo.bazar) {
      info.bazarId = paramInfo.bazar.id;
    }
    items.push(info);
  }

  param.items = items;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/v2/order/updateOrder`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createNewOrder(paramInfo) {
  let param = {};
  param.name = paramInfo.name;
  param.contactNumber = paramInfo.contactNumber;
  param.shouldUpdate = paramInfo.shouldUpdate;
  param.address = paramInfo.address;
  param.packingComment = paramInfo.packingComment;

  if (paramInfo.region) {
    param.regionId = paramInfo.region._id;
  }
  let items = [];
  for (const item of paramInfo.items) {
    let info = {};
    info.productId = item.product.id;
    info.quantity = item.quantity;
    if (item.variant) {
      info.variantId = item.variant._id;
    }
    if (paramInfo.bazar) {
      info.bazarId = paramInfo.bazar.id;
    }
    items.push(info);
  }

  param.items = items;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/v2/order/createUserOrder`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function regionForPincode(pinCode) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let path = `${config.apiUrl}/v2/order/regionForPincode?pinCode=${pinCode}`;

  return fetch(path, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function cancelOrder(orderId) {
  let param = {orderId};
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/cancel`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateStatus(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/v2/order/updateStatus`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function delivered(orderId) {
  let param = {orderId};
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/v2/order/delivered`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function dispatchOrder(onfo) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(onfo),
  };

  return fetch(`${config.apiUrl}/v2/order/itemsDispatched`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function refundOrder(onfo) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(onfo),
  };

  return fetch(`${config.apiUrl}/order/updateRefund`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function fetchOrderForReceipt(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/order/public/${orderId}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteOrder(orderId) {
  let param = {orderId};
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/deleteOrder`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function initiateOrder(orderId) {
  let param = {orderId};
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/initiate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function paymentStatus(orderId, paymentStatus) {
  let param = {orderId, paymentStatus};
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/updatePaymentStatus`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function confirmOrders(onfo) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(onfo),
  };

  return fetch(`${config.apiUrl}/order/confirm`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getReport(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/fetchStatusReport`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function generateFarmersBill(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/order/generateFarmersBill`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
