import {CategoryConstants} from "./types";
import {categoryService} from "../services";

export const categoryActions = {
  fetchAllCategories,
};

function fetchAllCategories() {
  return (dispatch) => {
    dispatch({type: CategoryConstants.FETCH_CATEGORIES_REQUEST});

    categoryService.getAllCategories().then(
      (categories) => {
        dispatch(success(categories));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(categories) {
    return {type: CategoryConstants.FETCH_CATEGORIES_SUCCESS, categories};
  }
  function failure(error) {
    return {type: CategoryConstants.FETCH_CATEGORIES_FAIL, error};
  }
}
