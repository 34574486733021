import config from "./env";
import {authHeader, headerForMulipart} from "../config/auth-header";
import {handleResponse} from "./responseHandler";

export const userService = {
  bootLoad,
  createAdmin,
  login,
  logout,
  getAll,
  fetchManager,
  getById,
  update,
  delete: _delete,
  loadDashboard,
  validateOtp,
  resendOtp,
  createFarmer,
  fetchFarmer,
  deleteFarmer,
  updateFarmer,
  uploadFarmerIcon,
  uploadFarmerBanner,
  fetchUserInfo,
};

function bootLoad() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/bootSetup`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createAdmin(admin) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(admin),
  };

  return fetch(`${config.apiUrl}/users/createAdmin`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({username, password}),
  };

  return fetch(`${config.apiUrl}/users/authenticateAdmin`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (user !== "undefined" && user.varified === true) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
}

function loadDashboard() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/getSiteInfo`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll(offset, search) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/users/all?size=10&offset=${offset}&search=${search}`,
    requestOptions
  ).then(handleResponse);
}

function fetchUserInfo(contactNo) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/users/fetchInfoForNo?contactNo=${contactNo}`,
    requestOptions
  ).then(handleResponse);
}

function fetchManager(offset, search) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/users/allManager?size=10&offset=${offset}&search=${search}`,
    requestOptions
  ).then(handleResponse);
}

function fetchFarmer(offset, size, key) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/users/allFarmers?size=${size}&offset=${offset}&search=${key}`,
    requestOptions
  ).then(handleResponse);
}

function deleteFarmer(farmerId) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify({id: farmerId}),
  };

  return fetch(`${config.apiUrl}/users/deleteFarmerById`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse
  );
}

function createFarmer(param) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/users/createFarmer`, requestOptions).then(
    handleResponse
  );
}

function updateFarmer(param) {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/users/updateFarmerById`, requestOptions).then(
    handleResponse
  );
}

function uploadFarmerIcon(param) {
  const formData = new FormData();
  formData.append("id", param.id);
  formData.append("iconImage", param.iconImage);

  const requestOptions = {
    method: "POST",
    headers: {
      ...headerForMulipart(),
    },
    body: formData,
  };

  return fetch(`${config.apiUrl}/users/uploadFarmIcon`, requestOptions).then(
    handleResponse
  );
}

function uploadFarmerBanner(param) {
  const formData = new FormData();
  formData.append("id", param.id);
  formData.append("bannerImage", param.bannerImage);

  const requestOptions = {
    method: "POST",
    headers: {...headerForMulipart()},
    body: formData,
  };

  return fetch(`${config.apiUrl}/users/uploadFarmBanner`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function validateOtp(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/users/validateOtp`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (user !== "undefined" && user.varified === true) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
}

function resendOtp(param) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(param),
  };

  return fetch(`${config.apiUrl}/users/resendOtp`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
